import React, { useState, useEffect } from "react";
import {
  InputDateRange,
  InputSelect,
  SearchBox,
  Button,
  WidgetCard,
} from "@bluesilodev/timhutcomponents";
import { FormikProvider, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AttendanceDataSVG from "assets/icon/AttendanceDataSVG/AttendanceDataSVG";
import AttendanceDataCardSVG from "assets/icon/AttendanceDataCardSVG/AttendanceDataCardSVG";
import UserAdd from "assets/icon/UserAddSVG/UserAdd";
import FetchingAndError from "components/fetchingAndError";
import { AttendanceApprovalRequestAttendanceModalForm } from "pages/AttendanceRequest/forms";
import { AttendanceDataDataTable } from "pages/AttendanceData/tables";
import { useAlertApi } from "services/alert-api";
import axios from "services/axios";
import {
  useFetchAttendanceQuery,
  useAddPunchInAttendanceMutation,
} from "store/apis/attendanceDataApi";
import { useAddAttendanceRequestMutation } from "store/apis/attendanceRequestApi";
import { useFetchLocationDepartmentQuery } from "store/apis/externalApi";

// import { login } from "services/authApi";
// import { loginSuccess } from "store/slices/authSlice";
// import { useAttendanceData } from "store/slices/attendanceDataSlice";

const AttendanceData = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // Redux State
  const { currentRole } = useSelector((state) => state.userData);
  // Formik
  const formik = useFormik({
    initialValues: {
      attID: "",
      scheduleID: [],
      uId: "3daf2237-28d2-49e9-bb04-7e665c77763b",
      punchInDate: "",
      punchInTime: "00:00",
      punchOutDate: "",
      punchOutTime: "00:00",
      notes: "",
    },
    validationSchema: Yup.object().shape({
      attID: Yup.string(),
      uId: Yup.string(),
      punchInDate: Yup.string().required("Punch In Date is required"),
      punchInTime: Yup.string().required("Punch In Time is required"),
      punchOutDate: Yup.string().required("Punch Out Date is required"),
      punchOutTime: Yup.string().required("Punch Out Time is required"),
      notes: Yup.string().required("Notes is required"),
    }),
    validateOnBlur: true,
    onSubmit: (values) => {
      const separatePunchInTime = values.punchInTime.split(":");
      const separatePunchInDate = values.punchInDate.split("/");
      const separatePunchOutTime = values.punchOutTime.split(":");
      const separatePunchOutDate = values.punchOutDate.split("/");
      const punchInDate = new Date(
        separatePunchInDate[2],
        separatePunchInDate[1] - 1,
        separatePunchInDate[0],
        separatePunchInTime[0],
        separatePunchInTime[1],
        0
      );
      const punchOutDate = new Date(
        separatePunchOutDate[2],
        separatePunchOutDate[1] - 1,
        separatePunchOutDate[0],
        separatePunchOutTime[0],
        separatePunchOutTime[1],
        0
      );
      requestAttendanceRequest({
        uId: values.uId,
        shift: values.scheduleID,
        punchIn: punchInDate,
        punchOut: punchOutDate,
        notes: values.notes,
      });
      handleCloseModal();
    },
  });
  // Data State
  const [summary, setSummary] = useState({
    onTime: 0,
    late: 0,
    absence: 0,
    onLeave: 0,
    noPunchInOut: 0,
    overtime: 0,
  });
  const [dataTable, setDataTable] = useState([]);
  const [globalFilter, setGlobalFilter] = useState({ locations: [] });
  const [locations, setLocations] = useState([{ label: "", value: "" }]);
  const [departments, setDepartments] = useState([{ label: "", value: "" }]);
  const [paginate, setPaginate] = useState({
    totalData: 0,
    rowsPerPage: 10,
    currentPage: 1,
  });
  const [filterData, setFilterData] = useState({
    search: "",
    searchDateEnable: 0,
    startDate: new Date(),
    endDate: new Date(),
    location: "",
    department: "",
    masterType: "",
  });
  // Element State
  const [timerStopTyping, setTimerStopTyping] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Redux Toolkit
  const {
    data: dataLocationDep,
    error: errorLocationDep,
    isFetching: isFetchingLocationDep,
  } = useFetchLocationDepartmentQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data,
    error: errorAttendance,
    isFetching: isFetchingAttendance,
  } = useFetchAttendanceQuery(
    { ...filterData, limit: paginate.rowsPerPage, page: paginate.currentPage },
    { refetchOnMountOrArgChange: true }
  );
  const [requestAttendanceRequest, requestAttendanceRequestResult] =
    useAddAttendanceRequestMutation();
  const [requestAttendancePunchIn, requestAttendancePunchInResult] =
    useAddPunchInAttendanceMutation(); // TODO Delete
  // Static State
  const dataTimeRange = [
    "Today",
    "Past Week",
    "Last Month",
    "This Month",
    "Custom Date",
  ];
  const dataLocation = ["Cafe Halim #2", "Cafe Willy"];
  const dataDepartment = ["QA", "IT"];
  let dataLength;
  if (data && data.data) {
    dataLength = data.data.length;
  } else {
    dataLength = 0;
  }

  useAlertApi(requestAttendanceRequestResult);
  useAlertApi(requestAttendancePunchInResult); // TODO Delete

  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const firstDay = new Date(y, m, 1);
  const lastDay = new Date(y, m + 1, 1);
  React.useEffect(() => {
    setFilterData((oldV) => ({
      ...oldV,
      searchDateEnable: oldV.searchDateEnable + 1,
      startDate: firstDay,
      endDate: lastDay,
    }));
  }, []);

  React.useEffect(() => {
    const departmentTempData = globalFilter.locations
      .filter(
        (item) =>
          item.locationName === filterData.location ||
          filterData.location === ""
      )
      .flatMap((entry) => entry.departments)
      .map((item, index) => {
        return {
          label: item.department,
          value: item.department,
        };
      });
    const departmentFInal = [];
    departmentTempData?.forEach((item) => {
      if (!departmentFInal.some((single) => single.label === item.label)) {
        departmentFInal.push({
          label: item.label,
          value: item.value,
        });
      }
    });
    setDepartments(departmentFInal);
  }, [filterData]);

  React.useEffect(() => {
    if (dataLocationDep) {
      const department = [];
      const location = dataLocationDep.data.map((locationVal) => {
        locationVal.departments.forEach((departmentVal) => {
          if (
            department.find((value) => value.value === departmentVal.department)
          )
            return;
          department.push({
            value: departmentVal.department,
            label: departmentVal.department,
          });
        });
        return {
          value: locationVal.locationName,
          label: locationVal.locationName,
        };
      });
      setDepartments(department);
      setLocations(location);
      setGlobalFilter({ locations: dataLocationDep.data });
    }
  }, [dataLocationDep]);

  React.useEffect(() => {
    if (data) {
      const switchCaseStatus = (status) => {
        switch (status) {
          case "OnTime":
            return "On Time";
          case "Late":
            return "Late";
          case "Absence":
            return "Absence";
          case "NoPunchInOut":
            return "No Punch In Out";
          case "OnLeave":
            return "On Leave";
          case "Overtime":
            return "Overtime";
          default:
            return "Absence";
        }
      };
      setSummary({
        onTime: data.group.OnTime,
        late: data.group.Late,
        absence: data.group.Absence,
        onLeave: data.group.OnLeave,
        noPunchInOut: data.group.NoPunchInOut,
        overtime: data.group.Overtime,
      });
      setPaginate((oldData) => ({ ...oldData, totalData: data.totalDoc }));
      const attendanceData = data.data.map((val) => {
        return {
          ...val,
          status: switchCaseStatus(val.status),
        };
      });
      setDataTable(attendanceData);
    }
  }, [data]);

  /*
  useEffect(() => {
    setQueryParams(filterDataByRole({ role })); // Initialize query params based on role
  }, [role]);

  // const role = useSelector((state) => state.auth?.user?.role);
  const { data, error, isLoading } = useAttendanceData(queryParams);

  useEffect(() => {
    const email = "dion@gmail.com"; //employee
    // const email = "tony@gmail.com"; //supervisor 
    // const email = "willy@gmail.com"; //admin
    const password = "123456";

    const performLogin = async () => {
      try {
        const token = await login(email, password);

        dispatch(loginSuccess(token));
      } catch (error) {
        console.error("Login failed:", error);
      }
    };

    performLogin();
  }, [dispatch]);
  */

  /*
  useEffect(() => {
    if (data && data.data) {
      const newSummary = data.data.reduce((acc, record) => {
        const status = record.status.toLowerCase().replace(/\s/g, ""); // Normalisasi teks status

        acc[status]++; // Langsung tambahkan jumlah status ke accumulator

        return acc;
      }, { ontime: 0, late: 0, absence: 0, onleave: 0, nopunchinout: 0, overtime: 0 });

      setSummary(newSummary);
    }
  }, [data]);
  */

  //Handle Query Input
  const handleInputChangeFilterSearch = (field, value) => {
    setFilterData((prevParams) => ({
      ...prevParams,
      [field]: value,
    }));
  };

  const filterDataByRole = (params, role) => {
    if (role === "admin" || role === "supervisor" || role === "employee") {
      return { ...params, role: role };
    } else {
      console.error("Role tidak valid");
      return params;
    }
  };

  const clickExportAttendanceData = () => {
    axios
      .get(
        `/api/attendance/?export=true&${Object.entries(
          {
            ...filterData,
            limit: paginate.rowsPerPage,
            page: paginate.currentPage,
          } || {}
        )
          .map((val) =>
            !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : ""
          )
          .join("&")}`
      )
      .then((response) => {
        const href = URL.createObjectURL(
          new Blob([response.data], { type: "text/csv;charset=utf-8," })
        );
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Timhut_attendance_data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClickMasterType = (dataType) => {
    let allFilterData = { ...filterData };

    switch (dataType) {
      case "onTime":
        allFilterData.masterType = "OnTime";
        break;
      case "late":
        allFilterData.masterType = "Late";
        break;
      case "absence":
        allFilterData.masterType = "Absence";
        break;
      case "onLeave":
        allFilterData.masterType = "OnLeave";
        break;
      case "noPunchInOut":
        allFilterData.masterType = "NoPunchInOut";
        break;
      case "overtime":
        allFilterData.masterType = "Overtime";
        break;
      default:
        allFilterData.masterType = "";
        break;
    }
    setFilterData(allFilterData);
  };

  const onChangeTypeSearch = (e) => {
    clearTimeout(timerStopTyping);
    const newTimer = setTimeout(() => {
      setFilterData((oldV) => ({ ...oldV, search: e.target.value }));
    }, 500);
    setTimerStopTyping(newTimer);
  };

  return (
    <>
      {location.pathname === "/" && (
        <div className="main w-full relative mt-5 p-1">
          <FormikProvider value={formik}>
            <AttendanceApprovalRequestAttendanceModalForm
              open={isModalOpen}
              handleClose={handleCloseModal}
            />
          </FormikProvider>
          <div className="flex items-center">
            <div className="input-select w-[350px] ">
              <InputDateRange
                label={"Time Range"}
                value={[filterData.startDate, filterData.endDate]}
                setFieldValue={(_, resultArrayDate) => {
                  setFilterData((oldV) => ({
                    ...oldV,
                    searchDateEnable: oldV.searchDateEnable + 1,
                    startDate: resultArrayDate[0],
                    endDate: resultArrayDate[resultArrayDate.length - 1],
                  }));
                }}
                error={""}
              />
            </div>
            <div className="pl-4 input-select w-[250px]">
              <InputSelect
                name={"select_location"}
                title={"Locations"}
                options={locations}
                defaultValue={{ label: "All Locations", value: "" }}
                required={false}
                classname={" h-[58px]"}
                onChange={(event) =>
                  handleInputChangeFilterSearch("location", event.target.value)
                }
              />
            </div>
            <div className="pl-4 input-select w-[250px]">
              <InputSelect
                name={"select_department"}
                title={"Departments"}
                options={departments}
                defaultValue={{ label: "All Departments", value: "" }}
                required={false}
                classname={"h-[58px]"}
                onChange={(event) =>
                  handleInputChangeFilterSearch(
                    "department",
                    event.target.value
                  )
                }
              />
            </div>
            <div className="ml-auto flex pl-4">
              <div>
                <SearchBox
                  onChange={(e) => onChangeTypeSearch(e)}
                  className={"h-[58px] w-[250px]"}
                  placeholder={"Search"}
                />
              </div>

              <div className="my-auto ml-4 ">
                {currentRole === "Admin" ? (
                  <Button
                    label={
                      <div className="flex px-4 gap-1">
                        <UserAdd color={"#DD7224"} />
                        <div>Export</div>
                      </div>
                    }
                    onClick={clickExportAttendanceData}
                    className={"w-[220px] h-[58px]"}
                  />
                ) : (
                  <Button
                    label={
                      <div className="flex items-center px-4 gap-1">
                        <AttendanceDataSVG color={"white"} />
                        <div>Request Attendance</div>
                      </div>
                    }
                    onClick={handleOpenModal}
                    style={"solid"}
                    className={"w-[220px] h-[58px]"}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex gap-5 mt-5">
            <div className=" flex items-center justify-center">
              <WidgetCard
                height="130px"
                onClick={() =>
                  filterData.masterType === "OnTime"
                    ? handleClickMasterType("")
                    : handleClickMasterType("onTime")
                }
                radius="lg"
                width="230px"
                className={`cursor-pointer rounded-lg shadow-xl flex items-center justify-center border-gray-300 border-2 ${
                  filterData.masterType === "OnTime" && "border-orange-500"
                }`}
              >
                <div className="flex flex-col items-center justify-center">
                  <div className="border-2 border-gray-300 rounded-full p-2">
                    <AttendanceDataCardSVG />
                  </div>
                  <div className="text-center">
                    <p class="input-label">
                      {currentRole === "Employee"
                        ? "On Time Records"
                        : "Employee On Time"}
                    </p>
                    <p className="text-lg font-semibold">
                      {summary.onTime} Employee
                    </p>
                  </div>
                </div>
              </WidgetCard>
            </div>
            <div className=" flex items-center justify-center">
              <WidgetCard
                height="130px"
                onClick={() =>
                  filterData.masterType === "Late"
                    ? handleClickMasterType("")
                    : handleClickMasterType("late")
                }
                radius="lg"
                width="230px"
                className={`cursor-pointer rounded-lg shadow-xl flex items-center justify-center border-gray-300 border-2 ${
                  filterData.masterType === "Late" && "border-orange-500"
                }`}
              >
                <div className="flex flex-col items-center justify-center">
                  <div className="border-2 border-gray-300 rounded-full p-2">
                    <AttendanceDataCardSVG />
                  </div>
                  <div className="text-center">
                    <p className="input-label">
                      {currentRole === "Employee"
                        ? "Late Records"
                        : "Employee Late"}
                    </p>
                    <p className="text-lg font-semibold">
                      {summary.late} Employee
                    </p>
                  </div>
                </div>
              </WidgetCard>
            </div>
            {/*
            <div className=" flex items-center justify-center">
              <WidgetCard
                height="130px"
                onClick={() => filterData.masterType === "Absence" ? handleClickMasterType("") : handleClickMasterType("absence")}
                radius="lg"
                width="230px"
                className={`cursor-pointer rounded-lg shadow-xl flex items-center justify-center border-gray-300 border-2 ${filterData.masterType === "Absence" && "border-orange-500"}`}
              >
                <div className="flex flex-col items-center justify-center">
                  <div className="border-2 border-gray-300 rounded-full p-2">
                    <AttendanceDataCardSVG />
                  </div>
                  <div className="text-center">
                    <p className="input-label">
                      {role === "user" ? "Absence Records" : "Employee Absence"}
                    </p>
                    <p className="text-lg font-semibold">
                      {summary.absence} Employee
                    </p>
                  </div>
                </div>
              </WidgetCard>
            </div>
            */}
            <div className=" flex items-center justify-center">
              <WidgetCard
                height="130px"
                onClick={() =>
                  filterData.masterType === "NoPunchInOut"
                    ? handleClickMasterType("")
                    : handleClickMasterType("noPunchInOut")
                }
                radius="lg"
                width="230px"
                className={`cursor-pointer rounded-lg shadow-xl flex items-center justify-center border-gray-300 border-2 ${
                  filterData.masterType === "NoPunchInOut" &&
                  "border-orange-500"
                }`}
              >
                <div className="flex flex-col items-center justify-center">
                  <div className="border-2 border-gray-300 rounded-full p-2">
                    <AttendanceDataCardSVG />
                  </div>
                  <div className="text-center">
                    <p className="input-label">
                      {currentRole === "Employee"
                        ? "No Punch In/Out Records"
                        : "No Punch In/Out"}
                    </p>
                    <p className="text-lg font-semibold">
                      {summary.noPunchInOut} Employee
                    </p>
                  </div>
                </div>
              </WidgetCard>
            </div>
            {/*
            <div className=" flex items-center justify-center">
              <WidgetCard
                height="130px"
                onClick={() => filterData.masterType === "OnLeave" ? handleClickMasterType("") : handleClickMasterType("onLeave")}
                radius="lg"
                width="230px"
                className={`cursor-pointer rounded-lg shadow-xl flex items-center justify-center border-gray-300 border-2 ${filterData.masterType === "OnLeave" && "border-orange-500"}`}
              >
                <div className="flex flex-col items-center justify-center">
                  <div className="border-2 border-gray-300 rounded-full p-2">
                    <AttendanceDataCardSVG />
                  </div>
                  <div className="text-center">
                    <p className="input-label">
                      {role === "user" ? "Leave Records" : "Employee On Leave"}
                    </p>
                    <p className="text-lg font-semibold">
                      {summary.onLeave} Employee
                    </p>
                  </div>
                </div>
              </WidgetCard>
            </div>
            */}
            <div className=" flex items-center justify-center">
              <WidgetCard
                height="130px"
                onClick={() =>
                  filterData.masterType === "Overtime"
                    ? handleClickMasterType("")
                    : handleClickMasterType("overtime")
                }
                radius="lg"
                width="230px"
                className={`cursor-pointer rounded-lg shadow-xl flex items-center justify-center border-gray-300 border-2 ${
                  filterData.masterType === "Overtime" && "border-orange-500"
                }`}
              >
                <div className="flex flex-col items-center justify-center">
                  <div className="border-2 border-gray-300 rounded-full p-2">
                    <AttendanceDataCardSVG />
                  </div>
                  <div className="text-center">
                    <p className="input-label">
                      {currentRole === "Employee"
                        ? "Overtime Records"
                        : "Employee Overtime"}
                    </p>
                    <p className="text-lg font-semibold">
                      {summary.overtime} Employee
                    </p>
                  </div>
                </div>
              </WidgetCard>
            </div>
          </div>
          <div className=" mt-5">
            <FetchingAndError
              isFetching={isFetchingAttendance}
              isError={errorAttendance}
            >
              <AttendanceDataDataTable
                dataTable={dataTable}
                currentPage={paginate.currentPage}
                totalData={paginate.totalData}
                rowsPerPage={paginate.rowsPerPage}
                onChangeCurrentPage={(val) =>
                  setPaginate((oldData) => ({
                    ...oldData,
                    currentPage: val + 1,
                  }))
                }
                onChangeRowsPerPage={(val) =>
                  setPaginate((oldData) => ({ ...oldData, rowsPerPage: val }))
                }
                onClickRedirect={(path) => navigate(path)}
              />
            </FetchingAndError>
          </div>
        </div>
      )}
      <Outlet />
    </>
  );
};

export default AttendanceData;
